$base : #F7F6F0;
$darken : darken(#F7F6F0 , 30%);
$back: darken(#F7F6F0, 40%);

$dark-blue: #227bb7; //search
$navigio-blue: #2584c4; // header
$deep-blue: #202a36; //survey
$baby-blue: #94beda; // client name
$grey-blue: #69757e; // table header
$dark-grey: #394750; // candidates
$black: #1c1c1c;
$grey-base: #eee; // background
$darker-grey: #e5e5e5; // search page
$grey-dark: #dadcde; // notification grey
$border-color: #d6d6d6; // border color
$text-grey: #959595; // search text and more(...) button
$darkest-grey: #5f5f5f; // notifications
$orange: #f19124;
$green: #33c764;
$red: #ec1543;
$superlightgrey: #a2abb1;
$menu-grey: #f5f5f5;
$menu-blue: #1779ba;
$neutral-grey: #e3e3e3;
$label-grey: #808080;

$success: lighten($green, 30%);
$alert: lighten($orange, 30%);
$error: #ffadad;
$info: lighten($navigio-blue, 45%);
$warning: #ef9034;

$border-radius: 3px;

$grid-breakpoints: (
  xxs: 0,
  xs: 320px,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1140px,
  xxl: 1280px
);

$directReport: #2073AA;
$peer: #278DD1;
$manager: #2584C4;
